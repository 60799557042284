const urlText = document.getElementById("url");
const destinationEl = document.getElementById("destination");
const statusEl = document.querySelector(".status");

/* Only register a service worker if it's supported */
if ("serviceWorker" in navigator) {
  const swPathName = "/service-worker.js";
  navigator.serviceWorker.register(swPathName);
}

async function checkUrl() {
  destinationEl.href = "";
  destinationEl.text = "";
  if (urlText.value) {
    statusEl.innerHTML = "🔍 checking...";
    const response = await fetch("/lookup", {
      method: "POST",
      body: JSON.stringify({ url: urlText.value }),
    });
    if (response.ok) {
      const result = await response.json();
      destinationEl.href = result.destination;
      destinationEl.text = result.destination;
      if (result.destination) statusEl.innerHTML = "✔ done!";
      else statusEl.innerHTML = "❌ failed!";
      return result;
    } else {
      statusEl.innerHTML = "❌ failed!";
      return;
    }
  } else statusEl.innerHTML = "❌ failed!";
}

async function gotoUrl() {
  console.log("gotoUrl");
  const result = await checkUrl();
  console.log("checkUrl done", result);
  if (destinationEl.href !== "") document.location = destinationEl.href;
}
function paste() {
  // urlText.focus();
  // document.execCommand("paste");
  navigator.clipboard.readText().then((clipText) => (urlText.value = clipText));
}

window.addEventListener("DOMContentLoaded", () => {
  document
    .querySelector(".btn-check")
    .addEventListener("click", () => checkUrl());
  document
    .querySelector(".btn-goto")
    .addEventListener("click", () => gotoUrl());
  /* Display paste button if paster is supported by the browser */
  if (typeof navigator.clipboard.readText !== "undefined") {
    document.querySelector(".btn-paste").style.display = "unset";
    urlText.style.borderRadius = "10px 0 0 10px";
    urlText.style.marginRight = "0";
    document
      .querySelector(".btn-paste")
      .addEventListener("click", () => paste());
  }
});

/* Preload and fire check when Web Share Target API is used */
window.addEventListener("DOMContentLoaded", () => {
  const parsedUrl = new URL(window.location);
  const sharedText = parsedUrl.searchParams.get("text");
  if (sharedText) {
    urlText.value = decodeURIComponent(sharedText);
    document.querySelector(".btn-check").click();
  }
});
